.right-code code .hll { background-color: #49483e }
.right-code code .c { color: #75715e } /* Comment */
.right-code code .err { color: #960050; background-color: #1e0010 } /* Error */
.right-code code .k { color: #66d9ef } /* Keyword */
.right-code code .l { color: #ae81ff } /* Literal */
.right-code code .n { color: #f8f8f2 } /* Name */
.right-code code .o { color: #f92672 } /* Operator */
.right-code code .p { color: #f8f8f2 } /* Punctuation */
.right-code code .cm { color: #75715e } /* Comment.Multiline */
.right-code code .cp { color: #75715e } /* Comment.Preproc */
.right-code code .c1 { color: #75715e } /* Comment.Single */
.right-code code .cs { color: #75715e } /* Comment.Special */
.right-code code .ge { font-style: italic } /* Generic.Emph */
.right-code code .gs { font-weight: bold } /* Generic.Strong */
.right-code code .kc { color: #66d9ef } /* Keyword.Constant */
.right-code code .kd { color: #66d9ef } /* Keyword.Declaration */
.right-code code .kn { color: #f92672 } /* Keyword.Namespace */
.right-code code .kp { color: #66d9ef } /* Keyword.Pseudo */
.right-code code .kr { color: #66d9ef } /* Keyword.Reserved */
.right-code code .kt { color: #66d9ef } /* Keyword.Type */
.right-code code .ld { color: #e6db74 } /* Literal.Date */
.right-code code .m { color: #ae81ff } /* Literal.Number */
.right-code code .s { color: #e6db74 } /* Literal.String */
.right-code code .na { color: #a6e22e } /* Name.Attribute */
.right-code code .nb { color: #f8f8f2 } /* Name.Builtin */
.right-code code .nc { color: #a6e22e } /* Name.Class */
.right-code code .no { color: #66d9ef } /* Name.Constant */
.right-code code .nd { color: #a6e22e } /* Name.Decorator */
.right-code code .ni { color: #f8f8f2 } /* Name.Entity */
.right-code code .ne { color: #a6e22e } /* Name.Exception */
.right-code code .nf { color: #a6e22e } /* Name.Function */
.right-code code .nl { color: #f8f8f2 } /* Name.Label */
.right-code code .nn { color: #f8f8f2 } /* Name.Namespace */
.right-code code .nx { color: #a6e22e } /* Name.Other */
.right-code code .py { color: #f8f8f2 } /* Name.Property */
.right-code code .nt { color: #f92672 } /* Name.Tag */
.right-code code .nv { color: #f8f8f2 } /* Name.Variable */
.right-code code .ow { color: #f92672 } /* Operator.Word */
.right-code code .w { color: #f8f8f2 } /* Text.Whitespace */
.right-code code .mf { color: #ae81ff } /* Literal.Number.Float */
.right-code code .mh { color: #ae81ff } /* Literal.Number.Hex */
.right-code code .mi { color: #ae81ff } /* Literal.Number.Integer */
.right-code code .mo { color: #ae81ff } /* Literal.Number.Oct */
.right-code code .sb { color: #e6db74 } /* Literal.String.Backtick */
.right-code code .sc { color: #e6db74 } /* Literal.String.Char */
.right-code code .sd { color: #e6db74 } /* Literal.String.Doc */
.right-code code .s2 { color: #e6db74 } /* Literal.String.Double */
.right-code code .se { color: #ae81ff } /* Literal.String.Escape */
.right-code code .sh { color: #e6db74 } /* Literal.String.Heredoc */
.right-code code .si { color: #e6db74 } /* Literal.String.Interpol */
.right-code code .sx { color: #e6db74 } /* Literal.String.Other */
.right-code code .sr { color: #e6db74 } /* Literal.String.Regex */
.right-code code .s1 { color: #e6db74 } /* Literal.String.Single */
.right-code code .ss { color: #e6db74 } /* Literal.String.Symbol */
.right-code code .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
.right-code code .vc { color: #f8f8f2 } /* Name.Variable.Class */
.right-code code .vg { color: #f8f8f2 } /* Name.Variable.Global */
.right-code code .vi { color: #f8f8f2 } /* Name.Variable.Instance */
.right-code code .il { color: #ae81ff } /* Literal.Number.Integer.Long */

.right-code code .gh { } /* Generic Heading & Diff Header */
.right-code code .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
.right-code code .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
.right-code code .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */
